import "./css/button.css"
import React from 'react';

const CmpButton = props =>{
    const { onClick, text, type, clase } = props;
    if (!clase)
    {
        return(
            <div className="CmpButton" onClick={onClick} type={type}>{text}</div>
        )
    }else {
        return(
            <div className={clase} onClick={onClick} type={type}>{text}</div>
        )
    }
    
}

export default CmpButton;