import React from 'react';
import "./css/filter.css"

const CmpCheckBox = props => {
    const { name, id, setEvent, checked } = props;

    return (
        <label>
            <input
                type="checkbox"
                id={id}
                onChange={event => setEvent(event.target.id)}
                checked={checked && 'checked'}
            />
            {name}
        </label>
    )

}

export default CmpCheckBox