import "./css/filter.css"
import React from 'react';

const CmpOrderBy = props => {
    const { setOrderBy } = props;

    return(
        <div className='CmpOrderBy'>
            Orden:&nbsp;
            <select id="inputCompania" onChange={event => setOrderBy(event.target.value)}>
                <option value="TotalAsc">Total Ascendiente</option>
                <option value="TotalDesc">Total Descenciente</option>
            </select>
        </div>
    )

}

export default CmpOrderBy